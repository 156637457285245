<template>
  <page-layout>
    <v-row>
      <v-col></v-col>
      <v-col class="shrink">
        <auth-user-form class="mb-5" />

        <redeem-coupon />
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import pageLayout from '@/layouts/Page'
import redeemCoupon from './RedeemCoupon'

export default {
  components: {
    pageLayout,
    redeemCoupon,
  },
}
</script>
