<template>
	<v-app>
		<template v-if="$route.meta && $route.meta.bg_img">
			<bg-img />
			<div class="ml-bg-img-mask"></div>
		</template>

		<v-app-bar v-if="!$route.meta.no_topbar && !appLayout.no_topbar" app flatx clipped color="white">
			<v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click.stop="toggleMenu" />
			<v-card class="px-2" flat rounded color="rgba(255,255,255,0.1)" exact :to="{ name: 'home' }">
				<v-toolbar-title>
					Mastery
					<span class="ml-n1">Labs</span>
				</v-toolbar-title>
			</v-card>

			<v-spacer />

			<!-- <messages-btn v-if="authenticated" /> -->

			<auth-user-menu v-if="authenticated" />
		</v-app-bar>

		<div
			v-else-if="authenticated && !$route.meta.no_profile && !appLayout.no_profile"
			style="position:fixed;right:7px;top:7px;z-index:1000"
		>
			<auth-user-menu />
		</div>

		<v-navigation-drawer
			v-if="menu.available && !appLayout.no_menu"
			v-model="menu.model"
			app
			:mini-variant="$route.meta.mini_menu || appLayout.mini_menu"
			:permanent="$route.meta.noMenu ? false : $vuetify.breakpoint.mdAndUp"
		>
			<app-menu v-for="(menu, index) in leftMenu" :menu="menu" :key="index" />
		</v-navigation-drawer>

		<v-main class="white">
			<slot />
			<msg-snackbar />
		</v-main>

		<m-pages-layout-footer />
	</v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import appMenu from '../components/Menu'
import bgImg from '../components/BgImg'

// import messagesBtn from '../mods/message/components/NavBtn';
export default {
	components: {
		appMenu,
		bgImg,
		// messagesBtn,
	},

	watch: {
		authenticated() {
			this.setup()
		},
	},

	data() {
		return {
			drawer: false,
			leftMenu: [],
		}
	},

	computed: {
		...mapGetters({
			navigationDrawer: 'navigationDrawer',
			menu: 'menu/menu',
			appLayout: 'app/layout',
		}),
	},

	methods: {
		...mapActions({
			setMenu: 'menu/setAvailable',
			toggleMenu: 'menu/toggle',
		}),
		setup() {
			if (this.authenticated) {
				this.setMenu(true)
				this.getMenu()
			} else {
				this.leftMenu = []
				this.setMenu(false)
			}
		},

		async getMenu() {
			// left menu
			let { data } = await this.$api.get('menu/left')

			this.leftMenu = data.data
		},
	},

	beforeMount() {
		if (this.authenticated) {
			this.setup()
		}
	},
}
</script>
