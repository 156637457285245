<template>
  <page-layout crumbs>
    <m-page :page="page" />
  </page-layout>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
      product: {},
      page: {},
    }
  },

  watch: {
    authReady() {
      this.onAuthReady()
    },
  },

  methods: {
    onAuthReady() {
      this.getItem()
    },

    getItem() {
      this.$api
        .get(this.$route.path)
        .then(({ data }) => {
          this.product = data.data.product
          this.page = data.data.page
          this.ready = true
        })
        .catch((err) => {
          console.log('NO ACCESS', err.response)
          if (this.authenticated) {
            return this.$router.replace({ name: 'my-products' })
          } else {
            this.$store.dispatch('auth/requireLogin', true)
          }
        })
    },
  },

  beforeMount() {
    if (this.authReady) {
      this.onAuthReady()
    }
  },
}
</script>
