<template>
  <v-container fill-height>
    <v-row fill-height>
      <v-col>
        <auth-recovery>
          <auth-header />
        </auth-recovery>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authHeader from "./components/AuthHeader";

export default {
  components: {
    authHeader,
  },
};
</script>
