const state = {
	layout: {
		no_topbar: false,
		no_profile: false,
		no_menu: false,
		mini_menu: false,
	},
};

const getters = {
	layout(state) {
		return state.layout;
	},
};

const mutations = {
	SET_LAYOUT(state, payload = {}) {
		for (let k in payload) {
			state.layout[k] = payload[k];
		}
	},
};

const actions = {
	setLayout({ commit }, payload) {
		commit('SET_LAYOUT', payload);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
