export default {
  onZoom({ commit }, zoom) {
    console.log("onZoom", zoom);
  },

  onBounds({ commit }, payload) {
    if (!payload) {
      return;
    }
    const bounds = payload.toJSON();
    const locationbias = `rectangle:${bounds.south},${bounds.west}|${bounds.north},${bounds.east}`;

    commit("SET_MAP", { bounds, ready: true });
    commit("SET_SEARCH", { locationbias });
  },

  onCenter({ commit }, payload) {
    commit("SET_MAP", { newCenter: payload.toJSON() });
  },

  onDragStart({ commit }, payload) {
    console.log("dragStart", payload);
  },
  onDragEnd({ commit }, payload) {
    console.log("dragEnd", payload);
  },
};
