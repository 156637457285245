const state = {
	loading: false,
	ready: false,
	data: {},
};

export default {
	namespaced: true,
	state,
};
