import Messages from './Messages';

export default [
	{
		path: '/inbox',
		name: 'messages',
		component: Messages,
		meta: {
			title: 'My Inbox',
			auth: true,
		},
	},
];
