export default {
  gmap(state) {
    return state.gmap;
  },
  search(state) {
    return state.search;
  },
  places(state) {
    return state.places;
  },
  drawer(state) {
    return state.drawer;
  },
  center(state) {
    if (state.gmap.newCenter) {
      return state.gmap.newCenter;
    }
    return state.gmap.center;
  },

  ready(state) {
    return state.gmap.ready;
  },

  mapType(state) {
    return state.gmap.mapTypeId;
  },

  autocompleteOptions(state) {
    const options = {
      strictBounds: true,
      types: [state.gmap.searchType],
      bounds: state.gmap.bounds,
    };
    return options;
  },
};
