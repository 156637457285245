const state = {
  data: {
    dialog: false,
    drawer: null,
  },
};

const getters = {
  data(state) {
    return state.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
