export default {
  gmap: {
    ready: false,
    center: { lat: 47.3769, lng: 8.5417 },
    newCenter: null,
    zoom: 10,
    mapTypeId: "roadmap",
    height: 500,
    bounds: {},
    newBounds: null,
    searchType: "establishment",
  },
  search: {
    loading: false,
    autocomplete: false,
    // locality
    input: "restaurant", //
    inputtype: "textquery",

    // https://developers.google.com/maps/faq#languagesupport
    language: "en", // de

    /**
     * Location Bias options
     * point:lat,lng
     * circle:radius@lat,lng
     * rectangle:south,west|north,east
     */
    locationbias: "",

    fields: [
      // Basic
      "business_status",
      "formatted_address",
      "geometry",
      "icon",
      "name",
      // "permanently_closed", // (deprecated),
      "photos",
      "place_id",
      "plus_code",
      "types",

      // Contact
      "opening_hours",
      "open_now",

      // Atmosphere
      "price_level",
      "rating",
      "user_ratings_total",
    ],
  },
  places: [],
  drawer: {
    open: false,
    width: 250,
  },
  options: {
    selectPlaceZoom: 13,
  },
};
