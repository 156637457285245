const fields = [
  {
    text: "Name",
    value: "name",
    header: true,
  },
  {
    text: "Description",
    value: "description",
  },
  {
    text: "Date",
    value: "created",
    header: true,
  },
];
export default fields;

export const headers = fields.filter((a) => a.header);
