<template>
  <!-- <v-app> -->
  <auth-wrapper>
    <!-- main content  -->
    <app-layout v-if="authReady || !$route.meta.auth">
      <router-view />
    </app-layout>

    <template slot="app-loading">APP LOADING...</template>

    <auth-header slot="auth-header" />

    <!-- <bg-img /> -->
  </auth-wrapper>
  <!-- </v-app> -->
</template>

<script>
import appLayout from "./layouts/AppLayout";
import authHeader from "./pages/auth/components/AuthHeader";
export default {
  components: {
    appLayout,
    authHeader,
    // bgImg,
  },
};
</script>

<style lang="scss">
@import "./sass/app.scss";
</style>
