import Vue from 'vue';
import Vuex from 'vuex';

// mods
import app from './app';
import menu from './menu';
import layout from './layout';
import findLeads from '../pages/find-leads/vuex';
import MPages from '@/mods/m-pages/store/m-pages';

import dashboard from '../pages/dashboard/vuex';
import MyProducts from '../pages/my-products/vuex';

import messages from '../mods/message/vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		ready: false,
		loading: false,
		navigationDrawer: {
			active: false,
		},
	},
	mutations: {
		SET_LOADING(state, value) {
			state.loading = value;
		},
		TOGGLE_NAVIGATION_DRAWER(state) {
			console.log('TOGGLE_NAVIGATION_DRAWER');
			state.navigationDrawer.active = !state.navigationDrawer.active;
		},
	},
	getters: {
		navigationDrawer(state) {
			return state.navigationDrawer;
		},
	},

	actions: {
		setLoading({ commit }, value = true) {
			commit('SET_LOADING', value);
		},
		toggleNavigationDrawer({ commit }) {
			commit('TOGGLE_NAVIGATION_DRAWER');
		},
	},
	modules: {
		app,
		menu,
		layout,
		findLeads,
		MPages,
		dashboard,
		MyProducts,
		messages,
	},
});
