<template>
  <custom-page slug="home" />
</template>

<script>
import customPage from "../custom-page/CustomPage";
export default {
  components: {
    customPage,
  },
};
</script>
