<template>
  <page-layout :loading="!ready" crumbs>
    <v-row>
      <v-col xs="12" sm="6" md="4" v-for="product in products" :key="product.slug">
        <v-hover v-slot:default="{ hover }">
          <v-card
            :class="`elevation-${hover ? 12 : 4}`"
            :to="{ name: 'my-product', params: { slug: product.slug } }"
            min-width="240"
          >
            <!-- product: {{product}} -->
            <v-img :src="product.image || 'https://source.unsplash.com/random'" height="200" />
            <v-card-title>{{ product.name }}</v-card-title>

            <v-card-actions>
              <v-btn
                :to="{ name: 'my-product', params: { slug: product.slug } }"
                text
                exact
                color="primary"
              >
                <!-- <v-icon class="mr-1">lock_open</v-icon> -->
                Select
              </v-btn>
              <v-spacer />
              <v-btn text>
                <v-icon class="mr-1">monetization_on</v-icon>Promote
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import pageLayout from "@/layouts/Page";
import { headers } from "@/mixins/fields/my-products";

export default {
  components: {
    pageLayout,
  },

  data() {
    return {
      ready: false,
      headers,
      products: [],
    };
  },

  beforeMount() {
    this.$api.get("/my-products").then(({ data }) => {
      this.products = [...data.data];
      this.ready = true;
    });
    // this.$store.dispatch('product/list')
  },
};
</script>
