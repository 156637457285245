<template>
  <page-layout :loading="!ready">
    <app-page v-if="ready" :page="data.data" />
  </page-layout>
</template>

<script>
import appPage from "@/mods/m-pages/Page";
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    appPage,
  },

  data() {
    return {
      ready: false,
    };
  },

  computed: {
    ...mapGetters({
      vuexReady: "dashboard/ready",
      data: "dashboard/data",
    }),
  },

  methods: {
    ...mapActions({
      setAppLayout: "app/setLayout",
    }),
    ...mapMutations({
      setData: "dashboard/SET_DATA",
    }),
    setPage() {
      const data = this.data;

      if (data.title) {
        window.document.title = data.title;
      }

      if (data.favicon) {
        // console.log("set favicon");
      }

      if (data.app_layout) {
        this.setAppLayout(data.app_layout);
      }
    },
  },

  beforeMount() {
    if (this.vuexReady) {
      this.ready = true;

      return;
    }
    // console.log(this.authReady);
    this.$api.get("/dashboard").then(({ data }) => {
      this.setData(data);
      this.setPage();
      this.ready = true;
    });
  },
};
</script>
