import Vue from 'vue'
import App from './AppV2'
import router from './router'
import store from './store'

// plugins

import vuetify from './plugins/vuetify'
import api from './plugins/api'
import auth from './plugins/auth'
import msg from './plugins/msg'
import editor from './plugins/editor'
// import ml from "./plugins/ml";

import vimg from './plugins/vimg'
import isMobile from './plugins/is-mobile'
Vue.use(isMobile)

import mPages from './mods/m-pages/app/client-plugin'

import localforage from 'localforage'

import { loadFormOnly } from './mods/form-builder'
loadFormOnly(Vue)

const appEl = document.querySelector('#app')

const isLocal = window.location.href.indexOf('localhost') === 7

localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: isLocal ? 'loc_masteryl' : 'masteryl',
  version: 1.0,
})
Vue.prototype.$localforage = localforage

import VuePlyr from 'vue-plyr'

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
  },
  emit: ['ended'],
})

Vue.use(mPages)
Vue.use(api, { url: appEl.getAttribute('data-api') })

Vue.use(auth, { store, router, disable: false, useWrapper: true })
Vue.use(msg, { store })
Vue.use(editor, { name: 'ml-editor', store })
// Vue.use(ml);

Vue.use(vimg)

/**
 * Figure Out Page
 */
// console.log("currentPage", appEl.getAttribute("data-page"));
let currentPage = false
if (appEl.hasAttribute('data-page')) {
  currentPage = appEl.getAttribute('data-page')
}
Vue.prototype.$currentPage = currentPage

/**
 * Global components
 */
Vue.component('page-layout', require('./layouts/Page.vue').default)

Vue.component(
  'm-raw-displayer',
  require('./components/base/form/RawDisplayer').default
)
Vue.component('m-crumbs', require('./components/base/Crumbs').default)
Vue.component('m-menu-item', require('./components/MenuItem').default)
Vue.component('m-menu-group', require('./components/MenuGroup').default)
Vue.component(
  'm-progress-linear',
  require('./components/ProgressLinear').default
)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  api,
  render: (h) => h(App),
  // created() {},
}).$mount('#app')
