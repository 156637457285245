<template>
  <div>
    <m-progress-linear v-if="!page" indeterminate />
    <v-fade-transition>
      <app-page v-if="page" :page="page" />
    </v-fade-transition>
  </div>
</template>

<script>
import loginPage from "../auth/Login";
import appPage from "@/mods/m-pages/Page";
import { mapActions } from "vuex";

export default {
  components: {
    appPage,
    loginPage,
  },

  props: {
    slug: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      page: null,
      requireLogin: false,
    };
  },

  computed: {
    getSlug() {
      if (this.slug) {
        return this.slug;
      }
      return this.$route.params.slug;
    },
  },

  watch: {
    authReady() {
      this.getItem(this.getSlug);
    },
    getSlug(slug) {
      // console.log("page changed!");
      this.page = null;
      this.getItem(slug);
    },
  },

  methods: {
    ...mapActions({
      setAppLayout: "app/setLayout",
    }),

    getItem(slug) {
      this.$api
        .get(`custom-page/${slug}`)
        .then(({ data }) => {
          if (data.title) {
            window.document.title = data.title;
          }

          if (data.favicon) {
            // console.log("set favicon");
          }

          if (data.app_layout) {
            this.setAppLayout(data.app_layout);
          }

          this.page = data.data;
        })
        .catch(({ response }) => {
          // page not found
          if (response.status && response.status == 404) {
            if (slug != "not-found") {
              return this.getItem("not-found");
            }
          } else {
            this.$store.dispatch("auth/requireLogin", true);
          }
        });
    },
  },

  beforeMount() {
    if (this.authReady) {
      this.getItem(this.getSlug);
    }
  },
};
</script>
