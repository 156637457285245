import events from "./events";

export default {
  ...events,
  selectPlace({ commit }, payload) {
    // console.log("select place", payload);
    const center = payload.geometry.location.toJSON();
    commit("SET_MAP", { center });
    console.log("center", center);
  },

  setSearchType({ commit }, searchType) {
    commit("SET_MAP", { searchType });
  },

  setMapType({ commit }, mapTypeId) {
    commit("SET_MAP", { mapTypeId });
  },

  searchNow({ commit, state }) {
    // fields

    const params = {
      input: state.search.input, //
      inputtype: state.search.inputtype,
      language: state.search.language,
      locationbias: state.search.locationbias,
    };

    // reset fields
    console.log(params);
    // rectangle:south,west|north,east
  },
};
// @bounds_changed="boundsChanged"
//       @center_changed=""
//       @zoom_changed=""
//       @dragstart=""
//       @dragend=""
//       @click="onClick"
//       @mousemove="mouseMove"
//       @mouseover="mouseOver"
//       @mouseout="mouseOut"
