<template>
  <form @submit.prevent="submit">
    <v-card>
      <v-card-title>Redeem Coupon</v-card-title>
      <v-card-text>
        <v-text-field
          ref="code"
          v-model="code"
          label="Coupon Code"
          outlined
        ></v-text-field>
        <v-btn
          type="submit"
          color="primary"
          :disabled="disabled"
          :loading="loading"
          >Apply</v-btn
        >
      </v-card-text>
    </v-card>
  </form>
</template>

<script>
export default {
  data() {
    return {
      code: '',
      loading: false,
    }
  },

  computed: {
    disabled() {
      return !this.code
    },
  },

  methods: {
    submit() {
      if (this.disabled) return this.$ref.code.focus()

      this.loading = true

      this.$api
        .post('redeem-coupon', {
          code: this.code,
        })
        .then(({ data }) => {
          this.loading = false
          if (data.message) {
            this.$msg.success(data.message)
          }

          if (data.redirect_url) {
            window.location.href = data.redirect_url
          }

          // video, content, other
        })
        .catch(({ response }) => {
          this.loading = false
          if (response.data.message) {
            this.$msg.error(response.data.message)
          }
        })
    },
  },
}
</script>
