<template>
  <page-layout :loading="!ready" crumbsx>
    <!-- {{ page }} -->
    <m-page :page="page" />
  </page-layout>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
      product: {},
      page: {},
    };
  },

  watch: {
    authReady() {
      this.getPage();
    },
  },

  methods: {
    getPage() {
      this.$api
        .get(this.$route.path)
        .then(({ data }) => {
          this.product = data.product;
          this.page = data.page;
          this.ready = true;
        })
        .catch((err) => {
          console.log("error", err);
          // this.$router.replace({ name: "my-products" });
        });
    },
  },

  beforeMount() {
    if (this.authReady) {
      this.getPage();
    }
  },
};
</script>
