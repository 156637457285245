import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/home/Home';
import Dashboard from '../pages/dashboard/Dashboard';
import MyProducts from '../pages/my-products/MyProducts';
import MyProduct from '../pages/my-products/MyProduct';
import MyAccount from '../pages/my-account/MyAccount';

// Promote
import Promote from '../pages/promote/Promote';
import PromoteProduct from '../pages/promote/PromoteProduct';

// import Editor from "../pages/editor/Editor";
import Login from '../pages/auth/Login';
import AuthRecovery from '../pages/auth/Recovery';
import CustomPage from '../pages/custom-page/CustomPage';

// import TestPage from "../pages/test/Test";
// import VimgEditor from "../plugins/vimg/pages/Editor";
// mods
// import findLeads from "../pages/find-leads/FindLeads";

// mods
import messages from '../mods/message/routes';

Vue.use(VueRouter);

const routes = [
	...messages,
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			title: 'MasteryLabs Home',
			auth: false,
			no_menu: true,
			no_topbar: true,
			mini_menu: true,
		},
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: {
			title: 'Dashboard',
			auth: true,
			// topbar: true,
		},
	},
	// {
	//   path: "/find-leads",
	//   name: "find-leads",
	//   component: findLeads,
	//   meta: {
	//     auth: true,
	//   },
	// },
	// {
	//   path: "/vimg-editor",
	//   name: "vimg-editor",
	//   component: VimgEditor,
	//   // meta: {},
	// },
	{
		path: '/login',
		name: 'auth-login',
		component: Login,
		meta: {
			title: 'MasteryLabs Login',
			unauth: true,
			no_topbar: true,
		},
	},
	{
		path: '/login/recovery/:token',
		name: 'auth-recovery',
		component: AuthRecovery,
		meta: {
			title: 'Login Help',
			no_topbar: true,
			no_menu: true,
			bg_img: true,
		},
	},

	// {
	//   path: "/editor",
	//   name: "Editor",
	//   component: Editor,
	//   meta: {
	//     no_topbar: true,
	//     no_menu: true,
	//   },
	// },
	{
		path: '/my-products',
		name: 'my-products',
		component: MyProducts,
		meta: {
			title: 'My Products',
			auth: true,
			icon: 'view_module',
		},
	},
	{
		path: '/my-products/:slug',
		name: 'my-product',
		title: 'My Products',
		component: MyProduct,
		meta: {
			auth: true,
			// icon: "view_module",
		},
	},
	{
		path: '/promote',
		name: 'promote',
		component: Promote,
		meta: {
			title: 'Promote',
			auth: false,
		},
	},
	{
		path: '/promote/:slug',
		name: 'promote-product',
		component: PromoteProduct,
		meta: {
			title: 'Promote',
			auth: false,
		},
	},
	{
		path: '/my-account',
		name: 'my-account',
		component: MyAccount,
		meta: {
			title: 'My Account',
			auth: true,
			authUserMenu: true,
			authTitle: 'My Account',
			icon: 'account_circle',
		},
	},
	{
		path: '/:slug',
		name: 'custom-page',
		component: CustomPage,
		meta: {
			// mini_menu: true,
			// no_topbar: true,
			// no_menu: true,
			// noProfile: true,
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

// router guard
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	if (!to.name) {
		return next({ name: 'home' });
	}

	const app = router.app;
	if (!app.authenticated && to.meta.auth && app.authReady) {
		return console.log('send to login');
		// return next({ name: "auth-login" });
	}

	// ok
	next();
});

export default router;
