<template>
  <v-img :src="bg.src" class="ml-bg-img" absolute :style="imgStyle" />
</template>

<script>
export default {
  data() {
    return {
      bg: {
        src: "https://source.unsplash.com/1200x1000/?scenic",
      },
      imgStyle: {
        filter: "grayscale(100%)",
      },
    };
  },
};
</script>
