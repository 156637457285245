export default {
  SET_MAP(state, payload) {
    for (let k in payload) {
      state.gmap[k] = payload[k];
    }
  },

  SET_SEARCH(state, payload) {
    for (let k in payload) {
      state.search[k] = payload[k];
    }
  },
};
