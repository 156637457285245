const state = {
  ready: false,
  data: {},
};

const getters = {
  ready(state) {
    return state.ready;
  },
  data(state) {
    return state.data;
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
    state.ready = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
